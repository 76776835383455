import "./Common.css";

function Label(props) {
  return (
    <div className="section">
      <h4>{props.data.roomName.toUpperCase()}</h4>
      <div className="labels">
        {
          props.data.labels.map(label => <div className="label" key={label}><span className="text-muted">{label}</span></div>)
        }
      </div>
    </div>
  );
}

export default Label;
