import "./App.css";
import Header from "./components/Header";
import LabelList from "./components/LabelList";
import { Route } from "react-router-dom";
import Welcome from "./pages/Welcome";
import Products from "./pages/Products";

function App() {
  return (
    <div className="container">
      <Route path="/inventory/counted/:inventoryId">
        <Header />
        <LabelList counted="true" />
      </Route>
      <Route path="/inventory/uncounted/:inventoryId">
        <Header />
        <LabelList counted="false" />
      </Route>
    </div>
  );
}

export default App;
