import './Common.css';
import './Header.css';
import { BrowserRouter as Router, Routes, Route, NavLink, Link, useParams } from 'react-router-dom';


function Header() {
    let { inventoryId, counted } = useParams();
    return <div className="container">
    <header className="d-flex justify-content-center py-3">
      <ul className="nav nav-pills">
        <li className="nav-item"><a href="https://koli.ozgurilhan.com" className="nav-link" aria-current="page">Taşımalar</a></li>
        <li className="nav-item"><NavLink activeClassName="nav-link active"  className="nav-link" to={'/inventory/uncounted/' + inventoryId}>Sayılmayanlar</NavLink></li>
        <li className="nav-item"><NavLink activeClassName="nav-link active" className="nav-link" to={'/inventory/counted/' + inventoryId}>Sayılanlar</NavLink></li>
      </ul>
    </header>
  </div>
  };
  export default Header;