import { useState } from "react";
import Label from "./Label";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const LabelList = (props) => {
  const [data, setLabels] = useState(null);
  let { inventoryId, counted } = useParams();

 useEffect(() => 
 {
    // console.log(inventoryId);
    axios.get('https://koli.ozgurilhan.com/Inventories/InventoryLabelList/' + inventoryId + '?counted=' + props.counted).then(res => {

      setLabels(res.data);
    }).catch(err => console.log(err));
 }, [])

  return (
    <div>
      <div className="table-responsive scrollbar">
        <table className="table">
          <tbody>
            <tr>
              <th>Müşteri Adı Soyadı :</th>
              <td>
                {data?.transportation.firstName}{" "}
                {data?.transportation.lastName}
              </td>
            </tr>
            <tr>
              <th>Tel : </th>
              <td>{data?.transportation.phoneNumber}</td>
            </tr>
            <tr>
              <th>Yükleme adresi : </th>
              <td>
                {data?.transportation.fromAddress},{" "}
                {data?.transportation.fromCity},{" "}
                {data?.transportation.fromCountry}
              </td>
            </tr>
            <tr>
              <th>Taşıma adresi :</th>
              <td>
                {data?.transportation.toAddress},{" "}
                {data?.transportation.toCity},{" "}
                {data?.transportation.toCountry}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {data?.roomAndLabels.map((roomAndLabel) => (
        <Label key={roomAndLabel.roomName} data={roomAndLabel}></Label>
      ))}
    </div>
  );
};

export default LabelList;
